import styled from 'styled-components';

export const Content = styled.div`
  section {
    display: flex;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 1140px;
      margin: 3rem auto;
      padding: 3rem 0;

      > div:first-child {
        margin-bottom: 2rem;
      }

      @media(max-width: 650px) {
        margin: 1rem auto;
        padding: 1rem 0;
      }
    }
  }

  section:nth-child(2), section:nth-child(4) {
    background: #343a40;
  }

  iframe {
    width: 100%;

    @media(max-width: 768px) {
      width: 90%;
      margin-left: 5%;
    }
  }
`;

export const News = styled.ul`
  list-style: none;

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-left: 1rem;

    & + li {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid #227755;
    }

    span {
      color: #FFF;
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;

      width: 60px;
      padding: 0.25rem 0;
      background: #227755;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
    }

    svg {
      margin-top: 0.5rem;
    }

    a {
      color: #ccc;
      font-size: 1.5rem;

      &:hover {
        color: #FFF;

      }
    }
  }

  @media(max-width: 768px) {
    padding: 0 1rem;
    li {
      span {
        width: 50px;
        font-size: 0.75rem;
      }

      a {
        font-size: 1.25rem;
      }
    }
  }
`;

export const Contact = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  width: 1000px;
  margin: 0 auto;

  strong {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.green};
    font-size: 1.5rem;
    margin: 0 0 0.5rem;

    svg {
      color: ${({ theme }) => theme.green};
      margin-right: 0.25rem;
    }
  }

  p {
    color: #444;
    font-size: 1.25rem;
    line-height: 28px;
  }

  @media(max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;

    strong {
      font-size: 1.125rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

export const Banner = styled.section`
  width: 100%;
  height: 650px;
  background-image: url(${(props) => props.urlImage});
  background-size: cover;
  background-position: 0 86%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: -2rem;

  @media(max-width: 768px) {
    height: 550px;
  }


  a {
    display: block;
    background: rgba(34, 40, 49, 0.85);

    color: #ddd;
    font-style: italic;
    font-size: 1.625rem;
    letter-spacing: 1.2px;

    transform: scale(0.95);
    transition: all ease 0.8s;

    &:hover {
      transform: scale(1);
      background: rgba(51, 153, 100, 1);
    }

    & + a {
      margin-top: 1rem;
    }
  }

  div:first-child a {
    text-align: right;
    margin-left: -1rem;
    border-radius: 0 1.5rem 0.25rem 0;
    padding: 1.5rem 1.5rem 1.5rem 6rem;
  }

  div:last-child a {
    margin-right: -1rem;
    border-radius: 1.5rem 0 0 0.25rem;
    padding: 1.5rem 6rem 1.5rem 1.5rem;
  }


  @media(max-width: 650px) {
    height: 70vh;

    a {
      background: rgba(34, 40, 49, 0.9);
      font-size: 1.125rem;
      letter-spacing: 0.5px;
    }

    div:first-child a {
      margin-left: -5px;
      border-radius: 0 1.5rem 0.25rem 0;
      padding: 1rem 1rem 1rem 1.5rem;
    }

    div:last-child a {
      margin-right: -5px;
      border-radius: 1.5rem 0 0 0.25rem;
      padding: 1rem 1.5rem 1rem 1rem;
    }
  }
`;
