import styled from 'styled-components';

export const AreasDeAtuacao = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;


  @media(max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const AreaContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${({ theme }) => theme.green};
  padding: 1.25rem;
  border-radius: 0.25rem;

  transition: all 0.4s;

  &:hover {
    cursor: pointer;
    transform: translateX(3px);
    transform: translateY(3px);
    box-shadow: 0px 3px 10px #373d4f26;
    background: ${({ theme }) => theme.green};

    div, svg, p {
      color: #fff;
    }
  }

  svg {
    width: 70px;
    height: 70px;
    margin-right: 1.25rem;
    transition: color 0.4s;
  }

  div {
    flex: 1;

    h3 {
      font-size: 1.25rem;
      margin: 0 0 0.5rem;
      transition: color 0.4s;
    }

    p {
      color: #777;
      font-size: 0.875rem;
      line-height: 1.25rem;
      transition: color 0.4s;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  @media(max-width: 768px) {
    margin: 0 1rem;
    padding: 1.5rem 1rem;

    svg {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1rem;
    }

    div {
      h3 {
        font-size: 1rem;
      }

      p {
        color: #777;
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

`;
