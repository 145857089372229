import axios from 'axios'
import Modal from 'react-modal'
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  FaNewspaper,
  FaInfoCircle,
  FaMapMarkedAlt,
  FaEnvelope,
  FaGavel,
  FaPhoneSquareAlt,
  FaWhatsapp,
  FaClock
} from 'react-icons/fa'
import ContentLoader from 'react-content-loader'

import SEO from '~/components/SEO'
import Title from '~/components/Title'
import Layout from '~/components/Layout'
import { AreasExpertise } from '~/components/AreasExpertise'
import { ModalVideoIntro } from '~/components/Modal/VideoIntro'

import { Content, Banner, News, Contact } from '~/styles/pages/home'

Modal.setAppElement('#___gatsby')

const QUERY_IMAGES = graphql`
{
  backgroundBanner: file(relativePath: { eq: "background.jpg" }) {
    childImageSharp {
      fluid(maxHeight: 590, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`

const MyLoader = () => (
  <ContentLoader
    speed={2}
    width={1000}
    height={20}
    backgroundColor="#4f5458"
    foregroundColor="#777777"
  >
    <rect rx="12" ry="12" width="100%" height="100%" />
  </ContentLoader>
)

const IndexPage = () => {
  const { backgroundBanner } = useStaticQuery(QUERY_IMAGES)

  const areasBanner = [
    ['Direito Empresarial', 'Direito Trabalhista', 'Direito de Família', 'Direito Tributário', 'Direito Civil'],
    ['Análise de Contratos', 'Sustentações Orais', 'Previdência Social', 'Sucessões']
  ]

  const [news, setNews] = useState({})

  useEffect(() => {
    async function loadNews() {
      const response = await axios.get('https://casagrande-adv-api.vercel.app/api/get-news')
      setNews(response.data)
    }
    loadNews()
  }, [])

  const [nofitication, setNotification] = useState(true)

  return (
    <Layout>
      <SEO title="Página Inicial" description="Contamos com uma equipe de diversos profissionais das Áreas do Direito e afins. Nossa equipe passa por constante aprimoramento e atualização técnica para fazer face à atuação nos diversos ramos do direito. Advogados com mais de 22 anos de atuação, com Titulação de Mestres e Especialistas, professores de diversas Instituições de Ensino Superior, em nível de Graduação e Pós-Graduação." />

      {/* <ModalVideoIntro isOpen={nofitication} onClose={() => setNotification(false)} /> */}

      <Banner urlImage={backgroundBanner.childImageSharp.fluid.src}>
        {areasBanner.map((item) => (
          <div key={item}>
            {item.map((area) => (
              <AniLink key={area} to={`/areas-de-atuacao#${area.replace(/\W/g, '')}`}>
                {area}
              </AniLink>
            ))}
          </div>
        ))}
      </Banner>
      <Content>
        <section>
          <div>
            <Title content="ÁREAS DE ATUAÇÃO" icon={<FaGavel />} />
            <AreasExpertise />
          </div>
        </section>

        <section>
          <div>
            <Title content="ÚLTIMAS NOTÍCIAS" icon={<FaNewspaper />} isDark />
            {console.log(news)}
            <News>
              {/* <li>
                <span>STF</span>
                {news.stf ? <a href={news.stf[0].url} target="__new">{news.stf[0].title}</a> : (
                  <MyLoader />
                )}
              </li> */}
              <li>
                <span>TST</span>
                {news.tst ? <a href={news.tst[0].url} target="__new">{news.tst[0].title}</a> : (
                  <MyLoader />
                )}
              </li>
              <li>
                <span>TRF4</span>
                {news.trf4 ? <a href={news.trf4[0].url} target="__new">{news.trf4[0].title}</a> : (
                  <MyLoader />
                )}
              </li>
            </News>
          </div>
        </section>

        <section>
          <div>
            <Title content="CONTATO" icon={<FaInfoCircle />} />
            <Contact>
              <div>
                <strong><FaWhatsapp /> WhatsApp</strong>
                <p>(047) 99651-2446</p>
              </div>
              <div>
                <strong><FaPhoneSquareAlt /> Telefone Comercial</strong>
                <p>(047) 3205-1200</p>
              </div>
              <div>
                <strong><FaEnvelope /> Email</strong>
                <p>joinville@casagrande-advogados.adv.br</p>
              </div>
              <div>
                <strong><FaClock /> Atendimento</strong>
                <p>Segunda - Sexta <br /> 08h00 às 12h00 - 13h30 às 18h00</p>
              </div>
            </Contact>
          </div>
        </section>

        <section>
          <div>
            <Title content="LOCALIZAÇÃO" icon={<FaMapMarkedAlt />} isDark />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57223.48264161427!2d-48.843363!3d-26.311352!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc7b711ecf40aa354!2sCASAGRANDE%20ADVOGADOS%20ASSOCIADOS!5e0!3m2!1spt-BR!2sbr!4v1594150792587!5m2!1spt-BR!2sbr"
              title="Frame da localização da advocacia no google maps"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            />
          </div>
        </section>
      </Content>
    </Layout>
  )
}

export default IndexPage
