import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  background: transparent;
  
  overflow: hidden;
  box-shadow: 0px 3px 6px #4b414129;

  video {
    width: auto;
    height: 80vh;
    border-radius: 10px;
    
    @media(max-width: 768px) {
      width: 95vw;
      height: auto;
    }
  }
`;