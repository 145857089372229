import React from 'react';
import {
  FaGavel,
  FaHandHoldingUsd,
  FaSuitcase,
  FaBaby,
  FaUserFriends,
  FaAddressCard,
  FaIdCardAlt,
  FaFileSignature,
  FaFileInvoice
} from 'react-icons/fa';

import { AreasDeAtuacao, AreaContent } from './styles';

export const AreasExpertise = () => {
  const areas = [
    { name: 'Direito Tributário', icon: <FaHandHoldingUsd />, content: 'Organização e reorganização tributária.Atualização e informação às empresas a respeito das novas legislações tributárias...' },
    { name: 'Direito Empresarial', icon: <FaSuitcase />, content: 'Com larga atuação neste ramo, a Casagrande Advogados Associados oferece a mais ampla Assessoria Jurídica Especializada para...' },
    { name: 'Direito de Família', icon: <FaBaby />, content: 'Divórcios, separações e defesas de interesse de menores contam com profissionais experientes...' },
    { name: 'Direito Civil', icon: <FaUserFriends />, content: 'Nesta esfera do direito a Casagrande Advogados Associados, busca a adequação do conjunto de normas jurídicas que dispõem...' },
    { name: 'Previdência Social', icon: <FaAddressCard />, content: 'Atua-se na defesa de interesse de segurados da Previdencia Social, com a busca do pleno acesso aos benefícios.' },
    { name: 'Direito Trabalhista', icon: <FaIdCardAlt />, content: 'A assessoria neste ramo do direito visa orientar, prevenir e conciliar conflitos de interesses. Reorganização na relação...' },
    { name: 'Análise de Contratos', icon: <FaFileSignature />, content: 'Orientações e defesas quanto a leis específicas e procedimentos especiais de cunho legal...' },
    { name: 'Sustentações Orais', icon: <FaGavel />, content: 'Atuação junto a Tribunais, inclusive com sustentações orais nas causas relacionadas ás áreas em que atuamos.' },
    { name: 'Sucessões', icon: <FaFileInvoice />, content: 'Atuamos em inventários judiciais ou extrajudiciais, conforme requerer o caso concreto.' }
  ];

  return (
    <AreasDeAtuacao>
      {areas.map((area, index) => (
        <AreaContent key={Number(index)}>
          {area.icon}
          <div>
            <h3>{area.name}</h3>
            <p>{area.content}</p>
          </div>
        </AreaContent>
      ))}
    </AreasDeAtuacao>
  );
};
